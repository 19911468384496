// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-integration-call-overview-request-schema',
  type: 'object',
  required: ['from', 'to'],
  properties: {
    from: {
      type: 'string',
      format: 'date-time',
    },
    to: {
      type: 'string',
      format: 'date-time',
    },
  },
} as IJSONSchema;

export default schema;
