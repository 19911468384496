// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/dynamic-form-field-payload-schema',
  type: 'object',
  required: ['id', 'values'],
  properties: {
    id: {
      type: 'string',
      minLength: 1,
    },
    values: {
      type: 'array',
      items: {
      },
    },
  },
} as IJSONSchema;

export default schema;
