// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetIntegrationCallStepsByCallIdRequestModel from '../../model/get-integration-call-steps-by-call-id-request-model';
import GetIntegrationCallStepsByCallIdRequestDTO from '../dto/get-integration-call-steps-by-call-id-request-dto';
import dtoSchema from '../schemas/get-integration-call-steps-by-call-id-request-schema';

export default abstract class GetIntegrationCallStepsByCallIdRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetIntegrationCallStepsByCallIdRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.runNo = data.runNo;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {int32}
  */
  runNo?: number;

  static toModel(dto: DeepPartial<GetIntegrationCallStepsByCallIdRequestDTO>): GetIntegrationCallStepsByCallIdRequestModel;
  static toModel(dto: DeepPartial<GetIntegrationCallStepsByCallIdRequestDTO> | undefined | null): GetIntegrationCallStepsByCallIdRequestModel | undefined;
  static toModel(dto: DeepPartial<GetIntegrationCallStepsByCallIdRequestDTO> | undefined | null): GetIntegrationCallStepsByCallIdRequestModel | undefined {
    return dto ? new GetIntegrationCallStepsByCallIdRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetIntegrationCallStepsByCallIdRequestModel> | GetIntegrationCallStepsByCallIdRequestModel): GetIntegrationCallStepsByCallIdRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      runNo: unwrapped.runNo,
    } as GetIntegrationCallStepsByCallIdRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetIntegrationCallStepsByCallIdRequestModel, can not map to GetIntegrationCallStepsByCallIdRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
