// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import DynamicFormContactSchema from './dynamic-form-contact-schema';
import DynamicFormFieldPayloadSchema from './dynamic-form-field-payload-schema';

const schema = {
  $id: '/dynamic-form-payload-schema',
  type: 'object',
  required: ['fields'],
  properties: {
    id: {
      type: 'string',
      nullable: true,
    },
    definitionId: {
      type: 'string',
      nullable: true,
    },
    contactId: {
      type: 'string',
      nullable: true,
    },
    contact: {
      ...DynamicFormContactSchema,
    },
    languageIso2: {
      type: 'string',
      nullable: true,
    },
    businessUnit: {
      type: 'integer',
      nullable: true,
    },
    fields: {
      type: 'array',
      items: { ...DynamicFormFieldPayloadSchema },
    },
    transactionId: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
