// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import HydrantMapItemModel from '../../model/hydrant-map-item-model';
import HydrantMapItemDTO from '../dto/hydrant-map-item-dto';
import dtoSchema from '../schemas/hydrant-map-item-schema';
import Int64LookupModel from '../../model/int64-lookup-model';
import HydrantMapItemIconModel from '../../model/hydrant-map-item-icon-model';

export default abstract class HydrantMapItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<HydrantMapItemDTO>) {
    super();
    if (data) {
      this.lookupData = data.lookupData ? Int64LookupModel.toModel(data.lookupData) : undefined;
      this.type = data.type ?? undefined;
      this.icon = data.icon ? HydrantMapItemIconModel.toModel(data.icon) : undefined;
      this.locationLat = data.locationLat;
      this.locationLng = data.locationLng;
      this.attributes = data.attributes ?? undefined;
    }
  }
  /**
  */
  lookupData?: Int64LookupModel;
  /**
  */
  type?: string;
  /**
  */
  icon?: HydrantMapItemIconModel;
  /**
  * @type {float}
  */
  locationLat?: number;
  /**
  * @type {float}
  */
  locationLng?: number;
  /**
  */
  attributes?: Record<any, string>;

  static toModel(dto: DeepPartial<HydrantMapItemDTO>): HydrantMapItemModel;
  static toModel(dto: DeepPartial<HydrantMapItemDTO> | undefined | null): HydrantMapItemModel | undefined;
  static toModel(dto: DeepPartial<HydrantMapItemDTO> | undefined | null): HydrantMapItemModel | undefined {
    return dto ? new HydrantMapItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<HydrantMapItemModel> | HydrantMapItemModel): HydrantMapItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      lookupData: unwrapped.lookupData,
      type: unwrapped.type,
      icon: unwrapped.icon,
      locationLat: unwrapped.locationLat,
      locationLng: unwrapped.locationLng,
      attributes: unwrapped.attributes,
    } as HydrantMapItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for HydrantMapItemModel, can not map to HydrantMapItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
