// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateHydrantRequestModel from '../../model/create-hydrant-request-model';
import CreateHydrantRequestDTO from '../dto/create-hydrant-request-dto';
import dtoSchema from '../schemas/create-hydrant-request-schema';

export default abstract class CreateHydrantRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateHydrantRequestDTO>) {
    super();
    if (data) {
      this.fireDepartmentId = data.fireDepartmentId;
      this.number = data.number;
      this.address = data.address;
      this.location = data.location;
      this.adapter75Count = data.adapter75Count;
      this.adapter55Count = data.adapter55Count;
      this.remark = data.remark ?? undefined;
      this.lat = data.lat;
      this.lng = data.lng;
    }
  }
  /**
  * @type {int64}
  */
  fireDepartmentId?: number;
  /**
  * @type {int32}
  */
  number?: number;
  /**
  * @minimum 1
  */
  address?: string;
  /**
  * @minimum 1
  */
  location?: string;
  /**
  * @type {int32}
  */
  adapter75Count?: number;
  /**
  * @type {int32}
  */
  adapter55Count?: number;
  /**
  */
  remark?: string;
  /**
  * @type {float}
  */
  lat?: number;
  /**
  * @type {float}
  */
  lng?: number;

  static toModel(dto: DeepPartial<CreateHydrantRequestDTO>): CreateHydrantRequestModel;
  static toModel(dto: DeepPartial<CreateHydrantRequestDTO> | undefined | null): CreateHydrantRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateHydrantRequestDTO> | undefined | null): CreateHydrantRequestModel | undefined {
    return dto ? new CreateHydrantRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateHydrantRequestModel> | CreateHydrantRequestModel): CreateHydrantRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      fireDepartmentId: unwrapped.fireDepartmentId,
      number: unwrapped.number,
      address: unwrapped.address,
      location: unwrapped.location,
      adapter75Count: unwrapped.adapter75Count,
      adapter55Count: unwrapped.adapter55Count,
      remark: unwrapped.remark,
      lat: unwrapped.lat,
      lng: unwrapped.lng,
    } as CreateHydrantRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateHydrantRequestModel, can not map to CreateHydrantRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
