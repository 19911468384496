<template>
  <p-container>
    <h2 class="mb-4">
      {{ $t('page.app.hydrantsAdmin.list.title') }}
    </h2>
    <p-request-alert v-model="error"></p-request-alert>
    <p-request-alert v-model="vm.hydrantsDataSource.error"></p-request-alert>
    <div v-if="vm.lookupFireDepartmentsDataSource.data && !error">
      <p-row>
        <p-row xs12 md6 lg2>
          <p-text-field
            v-model="vm.hydrantsDataSource.filter.searchCriteria"
            clearable
            :label="$t('core.app.search')"
            context-icon="search"
          ></p-text-field>
          <p-select
            v-model="selectedFireDepartment"
            :items="vm.lookupFireDepartmentItems"
            :label="$t('page.app.hydrantsAdmin.list.filter.fireDepartment')"
            @selection="updateFireDep"
          ></p-select>
        </p-row>
      </p-row>
      <p-row>
        <p-col>
          <p-card>
            <p-surface layer="2" class="pl-2 mb-2">
              <p-row align-center>
                <p-col shrink>
                  <p-button @click="vm.hydrantsDataSource.select()">
                    <p-icon dense>
                      refresh
                    </p-icon>
                  </p-button>
                  <p-button class="ml-2" @click="$router.push(`/Fw/Hydrants/admin/fireDepartment/${vm.hydrantsDataSource.filter.fireDepartmentId}/hydrants/create`)">
                    <p-icon dense class="mr-2">
                      add
                    </p-icon>
                    {{ $t('page.app.hydrantsAdmin.list.newEntry') }}
                  </p-button>
                </p-col>
              </p-row>
            </p-surface>
            <p-table v-if="vm.hydrantsDataSource.data" class="hydrantList">
              <template #rows>
                <tr>
                  <th>{{ $t('page.app.hydrants.properties.nr.short') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.location') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.address') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.adapter75') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.adapter55') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.latitude') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.longitude') }}</th>
                  <th>{{ $t('page.app.hydrants.properties.remark') }}</th>
                  <th></th>
                </tr>
                <tr v-for="hydrant in vm.hydrantsDataSource.data.items" :key="hydrant.data.id">
                  <td class="numberValues">
                    {{ hydrant.data.number }}
                  </td>
                  <td>
                    {{ hydrant.data.location }}
                  </td>
                  <td>
                    {{ hydrant.data.address }}
                  </td>
                  <td>
                    {{ hydrant.data.adapter75Count }}
                  </td>
                  <td>
                    {{ hydrant.data.adapter55Count }}
                  </td>
                  <td>
                    {{ hydrant.data.lat }}
                  </td>
                  <td>
                    {{ hydrant.data.lng }}
                  </td>
                  <td>
                    {{ hydrant.data.remark }}
                  </td>
                  <td>
                    <p-button
                      class="mr-2"
                      dense
                      :disabled="vm.hydrantsDataSource.isLoading"
                      @click="$router.push(`/Fw/Hydrants/admin/fireDepartment/${vm.hydrantsDataSource.filter.fireDepartmentId}/hydrants/${hydrant.data.id}/edit`)"
                    >
                      <p-icon dense>
                        edit
                      </p-icon>
                    </p-button>
                    <p-button
                      :disabled="vm.hydrantsDataSource.isLoading"
                      color="error"
                      dense
                      @click="vm.onDeleteClick(hydrant)"
                    >
                      <p-icon dense>
                        delete
                      </p-icon>
                    </p-button>
                  </td>
                </tr>
              </template>
            </p-table>
            <p-pagination v-model="vm.hydrantsDataSource.paging"></p-pagination>
          </p-card>
        </p-col>
      </p-row>
    </div>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import HydrantsListViewModel from './list.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      vm: new HydrantsListViewModel(),
      error: undefined as string | undefined,
      selectedFireDepartment: undefined as number | undefined,
    }),
    computed: {
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    async beforeMount() {
      await this.setFireDepartment();
    },
    async mounted() {
      await this.vm.initialize();
    },
    methods: {
      async setFireDepartment() {
        try {
          const user = await this.$auth.getUser();
          this.vm.fireDepartmentDataSource.filter.userId = user?.id!;
          const userFireDepartments = await this.vm.fireDepartmentDataSource.select();
          const lastVisitedFD: string | null = this.$localStorage.get('LastVisitedFireDepartment');
          if (lastVisitedFD && Number.parseInt(lastVisitedFD, 10)) {
            const fireDepId = Number.parseInt(lastVisitedFD, 10);
            if (this.isAdmin || (userFireDepartments.items.length > 0 && userFireDepartments.items?.some((fd) => fd.data.id === fireDepId))) {
              this.vm.hydrantsDataSource.filter.fireDepartmentId = fireDepId;
              this.selectedFireDepartment = fireDepId;
              return;
            }
          }

          if (this.isAdmin) {
            this.vm.hydrantsDataSource.filter.fireDepartmentId = 1;
            this.selectedFireDepartment = 1;
            this.$localStorage.set('LastVisitedFireDepartment', 1);
          } else if (userFireDepartments.items.length > 0) {
            this.vm.hydrantsDataSource.filter.fireDepartmentId = userFireDepartments.items[0].data.id;
            this.selectedFireDepartment = userFireDepartments.items[0].data.id;
            this.$localStorage.set('LastVisitedFireDepartment', userFireDepartments.items[0].data.id);
          } else {
            this.error = this.$t('core.error.network.unauthorized');
          }
        } catch (e) {
          this.$log.error(`Unexpected error setting fireDepartment: ${e}`);
        }
      },
      updateFireDep() {
        if (this.selectedFireDepartment) {
          this.$localStorage.set('LastVisitedFireDepartment', this.selectedFireDepartment);
          this.vm.hydrantsDataSource.filter.fireDepartmentId = this.selectedFireDepartment;
        }
      },
    },
  });
</script>
<style>
.numberValues{
  text-align: right;
}
</style>
