// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OnlinePaymentContactAddressModel from '../../model/online-payment-contact-address-model';
import OnlinePaymentContactAddressDTO from '../dto/online-payment-contact-address-dto';
import dtoSchema from '../schemas/online-payment-contact-address-schema';

export default abstract class OnlinePaymentContactAddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OnlinePaymentContactAddressDTO>) {
    super();
    if (data) {
      this.salutation = data.salutation ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.email = data.email ?? undefined;
      this.phoneNumber = data.phoneNumber ?? undefined;
      this.mobilePhoneNumber = data.mobilePhoneNumber ?? undefined;
      this.dateOfBirth = data.dateOfBirth ?? undefined;
      this.companyName = data.companyName ?? undefined;
      this.street = data.street ?? undefined;
      this.countryISO = data.countryISO ?? undefined;
      this.zipCode = data.zipCode ?? undefined;
      this.city = data.city ?? undefined;
    }
  }
  /**
  */
  salutation?: string;
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  */
  email?: string;
  /**
  */
  phoneNumber?: string;
  /**
  */
  mobilePhoneNumber?: string;
  /**
  * @type {date-time}
  */
  dateOfBirth?: string;
  /**
  */
  companyName?: string;
  /**
  */
  street?: string;
  /**
  */
  countryISO?: string;
  /**
  */
  zipCode?: string;
  /**
  */
  city?: string;

  static toModel(dto: DeepPartial<OnlinePaymentContactAddressDTO>): OnlinePaymentContactAddressModel;
  static toModel(dto: DeepPartial<OnlinePaymentContactAddressDTO> | undefined | null): OnlinePaymentContactAddressModel | undefined;
  static toModel(dto: DeepPartial<OnlinePaymentContactAddressDTO> | undefined | null): OnlinePaymentContactAddressModel | undefined {
    return dto ? new OnlinePaymentContactAddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OnlinePaymentContactAddressModel> | OnlinePaymentContactAddressModel): OnlinePaymentContactAddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      salutation: unwrapped.salutation,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      email: unwrapped.email,
      phoneNumber: unwrapped.phoneNumber,
      mobilePhoneNumber: unwrapped.mobilePhoneNumber,
      dateOfBirth: unwrapped.dateOfBirth,
      companyName: unwrapped.companyName,
      street: unwrapped.street,
      countryISO: unwrapped.countryISO,
      zipCode: unwrapped.zipCode,
      city: unwrapped.city,
    } as OnlinePaymentContactAddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OnlinePaymentContactAddressModel, can not map to OnlinePaymentContactAddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
