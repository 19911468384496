// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetHydrantMapItemsListRequestModel from '../../model/get-hydrant-map-items-list-request-model';
import GetPagedHydrantListRequestModel from '../../model/get-paged-hydrant-list-request-model';
import CreateHydrantRequestModel from '../../model/create-hydrant-request-model';
import GetHydrantByIdRequestModel from '../../model/get-hydrant-by-id-request-model';
import UpdateHydrantRequestModel from '../../model/update-hydrant-request-model';
import DeleteHydrantRequestModel from '../../model/delete-hydrant-request-model';
import HydrantMapItemListResultModel from '../../model/hydrant-map-item-list-result-model';
import HydrantPagingResultModel from '../../model/hydrant-paging-result-model';
import HydrantModel from '../../model/hydrant-model';

export default (service: Servicelayer) => ({
  /**  */
  async getHydrantMapItemsList(request: GetHydrantMapItemsListRequestModel, config?: RequestConfig) {
    const requestDTO = GetHydrantMapItemsListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-hydrant-map-items-list'] ?? 'Hydrants/HydrantMapItems';
    const response = await service.get<any>(endpointPath, {
      query: {
        fireDepartmentId: requestDTO.fireDepartmentId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(HydrantMapItemListResultModel.toModel(dto));
  },
  /**  */
  async getPagedHydrantList(request: GetPagedHydrantListRequestModel, config?: RequestConfig) {
    const requestDTO = GetPagedHydrantListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-paged-hydrant-list'] ?? 'Hydrants';
    const response = await service.get<any>(endpointPath, {
      query: {
        fireDepartmentId: requestDTO.fireDepartmentId,
        number: requestDTO.number,
        address: requestDTO.address,
        location: requestDTO.location,
        remark: requestDTO.remark,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(HydrantPagingResultModel.toModel(dto));
  },
  /**  */
  async createHydrant(request: CreateHydrantRequestModel, config?: RequestConfig) {
    const requestDTO = CreateHydrantRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-hydrant'] ?? 'Hydrants';
    const response = await service.post<any>(endpointPath, {
      body: {
        fireDepartmentId: requestDTO.fireDepartmentId,
        number: requestDTO.number,
        address: requestDTO.address,
        location: requestDTO.location,
        adapter75Count: requestDTO.adapter75Count,
        adapter55Count: requestDTO.adapter55Count,
        remark: requestDTO.remark,
        lat: requestDTO.lat,
        lng: requestDTO.lng,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getHydrantById(request: GetHydrantByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetHydrantByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-hydrant-by-id'] ?? 'Hydrants/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(HydrantModel.toModel(dto));
  },
  /**  */
  async updateHydrant(request: UpdateHydrantRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateHydrantRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-hydrant'] ?? 'Hydrants/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        fireDepartmentId: requestDTO.fireDepartmentId,
        number: requestDTO.number,
        address: requestDTO.address,
        location: requestDTO.location,
        adapter75Count: requestDTO.adapter75Count,
        adapter55Count: requestDTO.adapter55Count,
        remark: requestDTO.remark,
        lat: requestDTO.lat,
        lng: requestDTO.lng,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async deleteHydrant(request: DeleteHydrantRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteHydrantRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-hydrant'] ?? 'Hydrants/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
