// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import HydrantModel from '../../model/hydrant-model';
import HydrantDTO from '../dto/hydrant-dto';
import dtoSchema from '../schemas/hydrant-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class HydrantModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<HydrantDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.fireDepartmentId = data.fireDepartmentId;
      this.number = data.number;
      this.address = data.address;
      this.location = data.location;
      this.adapter75Count = data.adapter75Count;
      this.adapter55Count = data.adapter55Count;
      this.remark = data.remark ?? undefined;
      this.lat = data.lat;
      this.lng = data.lng;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  fireDepartmentId?: number;
  /**
  * @type {int32}
  */
  number?: number;
  /**
  * @minimum 1
  */
  address?: string;
  /**
  * @minimum 1
  */
  location?: string;
  /**
  * @type {int32}
  */
  adapter75Count?: number;
  /**
  * @type {int32}
  */
  adapter55Count?: number;
  /**
  */
  remark?: string;
  /**
  * @type {float}
  */
  lat?: number;
  /**
  * @type {float}
  */
  lng?: number;

  static toModel(dto: DeepPartial<HydrantDTO>): HydrantModel;
  static toModel(dto: DeepPartial<HydrantDTO> | undefined | null): HydrantModel | undefined;
  static toModel(dto: DeepPartial<HydrantDTO> | undefined | null): HydrantModel | undefined {
    return dto ? new HydrantModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<HydrantModel> | HydrantModel): HydrantDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      fireDepartmentId: unwrapped.fireDepartmentId,
      number: unwrapped.number,
      address: unwrapped.address,
      location: unwrapped.location,
      adapter75Count: unwrapped.adapter75Count,
      adapter55Count: unwrapped.adapter55Count,
      remark: unwrapped.remark,
      lat: unwrapped.lat,
      lng: unwrapped.lng,
    } as HydrantDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for HydrantModel, can not map to HydrantDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
