// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationCallModel from '../../model/integration-call-model';
import IntegrationCallDTO from '../dto/integration-call-dto';
import dtoSchema from '../schemas/integration-call-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class IntegrationCallModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationCallDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.integrationTaskId = data.integrationTaskId;
      this.integrationTaskName = data.integrationTaskName ?? undefined;
      this.sourceAppSystemId = data.sourceAppSystemId;
      this.sourceAppSystemName = data.sourceAppSystemName ?? undefined;
      this.integrationObject = data.integrationObject ?? undefined;
      this.integrationObjectSummary = data.integrationObjectSummary ?? undefined;
      this.integrationStatusId = data.integrationStatusId;
      this.integrationStatusName = data.integrationStatusName ?? undefined;
      this.integrationStatusMessage = data.integrationStatusMessage ?? undefined;
      this.runNo = data.runNo;
      this.correlationId = data.correlationId ?? undefined;
      this.appTransactionId = data.appTransactionId ?? undefined;
      this.appUserId = data.appUserId ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int64}
  */
  integrationTaskId?: number;
  /**
  */
  integrationTaskName?: string;
  /**
  * @type {int64}
  */
  sourceAppSystemId?: number;
  /**
  */
  sourceAppSystemName?: string;
  /**
  */
  integrationObject?: string;
  /**
  */
  integrationObjectSummary?: string;
  /**
  * @type {int64}
  */
  integrationStatusId?: number;
  /**
  */
  integrationStatusName?: string;
  /**
  */
  integrationStatusMessage?: string;
  /**
  * @type {int32}
  */
  runNo?: number;
  /**
  */
  correlationId?: string;
  /**
  */
  appTransactionId?: string;
  /**
  * @type {int64}
  */
  appUserId?: number;

  static toModel(dto: DeepPartial<IntegrationCallDTO>): IntegrationCallModel;
  static toModel(dto: DeepPartial<IntegrationCallDTO> | undefined | null): IntegrationCallModel | undefined;
  static toModel(dto: DeepPartial<IntegrationCallDTO> | undefined | null): IntegrationCallModel | undefined {
    return dto ? new IntegrationCallModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationCallModel> | IntegrationCallModel): IntegrationCallDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      integrationTaskId: unwrapped.integrationTaskId,
      integrationTaskName: unwrapped.integrationTaskName,
      sourceAppSystemId: unwrapped.sourceAppSystemId,
      sourceAppSystemName: unwrapped.sourceAppSystemName,
      integrationObject: unwrapped.integrationObject,
      integrationObjectSummary: unwrapped.integrationObjectSummary,
      integrationStatusId: unwrapped.integrationStatusId,
      integrationStatusName: unwrapped.integrationStatusName,
      integrationStatusMessage: unwrapped.integrationStatusMessage,
      runNo: unwrapped.runNo,
      correlationId: unwrapped.correlationId,
      appTransactionId: unwrapped.appTransactionId,
      appUserId: unwrapped.appUserId,
    } as IntegrationCallDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for IntegrationCallModel, can not map to IntegrationCallDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
