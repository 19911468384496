// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostDynamicFormRequestModel from '../../model/post-dynamic-form-request-model';
import PostDynamicFormRequestDTO from '../dto/post-dynamic-form-request-dto';
import dtoSchema from '../schemas/post-dynamic-form-request-schema';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';

export default abstract class PostDynamicFormRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostDynamicFormRequestDTO>) {
    super();
    if (data) {
      this.appSystemKey = data.appSystemKey;
      this.form = DynamicFormPayloadModel.toModel(data.form);
    }
  }
  /**
  * @minimum 1
  */
  appSystemKey?: string;
  /**
  */
  form?: DynamicFormPayloadModel;

  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO>): PostDynamicFormRequestModel;
  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO> | undefined | null): PostDynamicFormRequestModel | undefined;
  static toModel(dto: DeepPartial<PostDynamicFormRequestDTO> | undefined | null): PostDynamicFormRequestModel | undefined {
    return dto ? new PostDynamicFormRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostDynamicFormRequestModel> | PostDynamicFormRequestModel): PostDynamicFormRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      appSystemKey: unwrapped.appSystemKey,
      form: unwrapped.form,
    } as PostDynamicFormRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostDynamicFormRequestModel, can not map to PostDynamicFormRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
