<template>
  <p-container>
    <p-row no-gutters align-center>
      <p-col class="mr-1" shrink>
        <p-button tertiary @click="$router.push('/Fw/Hydrants/admin/hydrants')">
          <p-icon>arrow_back</p-icon>
        </p-button>
      </p-col>
      <p-col grow>
        <h2 class="mb-2">
          {{ $t('page.app.hydrantsAdmin.edit.title') }}
        </h2>
      </p-col>
    </p-row>
    <p-divider class="mb-4"></p-divider>
    <p-card v-if="vm.editHydrantDataSource.data" class="editView">
      <p-form :model="vm.editHydrantDataSource.data.data">
        <p-row class="editHydrantContainer">
          <p-col>
            <p-number-field v-model="vm.editHydrantDataSource.data.data.number" :label="$t('page.app.hydrants.properties.nr.long')" required></p-number-field>
            <p-text-field v-model="vm.editHydrantDataSource.data.data.address" :label="$t('page.app.hydrants.properties.address')" required></p-text-field>
            <p-text-field v-model="vm.editHydrantDataSource.data.data.location" :label="$t('page.app.hydrants.properties.location')" required></p-text-field>
            <p-number-field v-model="vm.editHydrantDataSource.data.data.adapter55Count" :label="$t('page.app.hydrants.properties.adapter55')" required></p-number-field>
            <p-number-field v-model="vm.editHydrantDataSource.data.data.adapter75Count" :label="$t('page.app.hydrants.properties.adapter75')" required></p-number-field>
            <p-textarea v-model="vm.editHydrantDataSource.data.data.remark" :label="$t('page.app.hydrants.properties.remark')"></p-textarea>
          </p-col>
          <p-divider vertical class="mr-2 ml-2"></p-divider>
          <p-col>
            <p-location
              :default-latitude="vm.editHydrantDataSource.data.data.lat"
              :default-longitude="vm.editHydrantDataSource.data.data.lng"
              @latitudeChange="vm.editHydrantDataSource.data.data.lat = $event"
              @longitudeChange="vm.editHydrantDataSource.data.data.lng = $event"
            ></p-location>
          </p-col>
        </p-row>
        <p-col class="ml-1 mt-2">
          <div class="caption-2">
            {{ $t('page.app.hydrantsAdmin.edit.id') }}: {{ vm.editHydrantDataSource.data.data.id }}
          </div>
          <div v-if="vm.editHydrantDataSource.data.data.created" class="caption-2">
            {{ $t('page.app.hydrantsAdmin.edit.created') }}: {{ $date(vm.editHydrantDataSource.data.data.created).format('DD.MM.YYYY HH:mm:ss') }}
          </div>
          <div v-if="vm.editHydrantDataSource.data.data.modified" class="caption-2">
            {{ $t('page.app.hydrantsAdmin.edit.modified') }}: {{ $date(vm.editHydrantDataSource.data.data.modified).format('DD.MM.YYYY HH:mm:ss') }}
          </div>
        </p-col>
        <p-row class="mt-4" justify-end>
          <p-button secondary @click="$router.push('/Fw/Hydrants/admin/hydrants')">
            {{ $t('page.app.hydrantsAdmin.cancel') }}
          </p-button>
          <p-button class="mr-4" @click="vm.updateHydrant()">
            {{ $t('page.app.hydrantsAdmin.save') }}
          </p-button>
        </p-row>
        <p-alert :value="vm.successMessage" type="success" class="mb-2" dismissable>
          {{ vm.successMessage }}
        </p-alert>
      </p-form>
    </p-card>
    <p-request-alert v-model="vm.editHydrantDataSource.error" class="mt-2"></p-request-alert>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import EditHydrantViewModel from './edit.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      vm: new EditHydrantViewModel(),
    }),
    mounted() {
      this.vm.editHydrantDataSource.filter.id = Number.parseInt(this.$route.params.hydrantId, 10);
      this.vm.initialize();
    },
  });
</script>
