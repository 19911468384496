/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import GetHydrantMapItemsListRequestModel from '@/src/services/v2/model/get-hydrant-map-items-list-request-model';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import HydrantMapItemModel from '@/src/services/v2/model/hydrant-map-item-model';
import { GoogleMapsApi } from '@glittr/frontend-core/src/plugins/map/mapProvider/googleMapsProvider';

export default class IndexPage extends ViewModelBase {
  async initialize(): Promise<void> {
    this.mapApi = await Vue.$map.google.initialize('core', 'maps', 'places', 'marker');
  }

  fireDepartmentDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.fireDepartments.getFireDepartmentList,
  });

  apiKey = 'AIzaSyDNWQikiNiP_uRQh9zyPZW9FghlAdUwkA8' as string;

  map :google.maps.Map | undefined = undefined;

  mapApi?: GoogleMapsApi;

  searchBox = undefined as any;

  marker = undefined as any;

  searchMarker = undefined as any;

  longitude = 0 as number;

  latitude = 0 as number;

  zoom = 18 as number;

  previousLatitude = 0 as number;

  previousLongitude = 0 as number;

  setMarker = { default: true, type: Boolean };

  mapContainerElement = {} as HTMLElement;

  searchFieldContainerElement = {} as HTMLInputElement;

  currentPositionMarker = undefined as any;

  showAddressSearchBox = false;

  searchBarRef = undefined as any;

  fireDepartmentId = undefined as number | undefined;

  // Text and directories
  currentLocationIcon = 'currentLocation.svg';

  searchedPosition = 'Gesuchter Standort';

  currentLocationTitle = 'Aktueller Standort';

  // Position for Home Button
  obstaldenPosition = {
    lat: 47.11916619,
    lng: 9.14333284,
  };

  // feuerwehr Position
  feuerwehrObstaldenPosition = {
    lat: 47.1174765953623,
    lng: 9.149508327579966,
  };

  // Zoom Level
  startPoint = 14;

  currentLocation = 20;

  home = 17;

  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  request = new GetHydrantMapItemsListRequestModel();

  hydrantMapItems: DataRecord<HydrantMapItemModel>[] = [];

  async populateGoogleMaps(customMapButtons : HTMLElement, searchBar: HTMLElement) {
    this.map = new this.mapApi!.maps.Map(this.mapContainerElement, {
      center: { lat: this.obstaldenPosition.lat, lng: this.obstaldenPosition.lng },
      mapTypeId: this.mapApi!.maps.MapTypeId.SATELLITE,
      zoom: this.startPoint,
      disableDefaultUI: false,
      mapId: (Vue.$config.values as any)['google-mapsmapid'],
    });
    this.mapApi!.core.event.addListenerOnce(this.map, 'idle', () => {
      this.mapContainerElement.firstChild?.appendChild(customMapButtons as HTMLElement);
      this.mapContainerElement.firstChild?.appendChild(searchBar as HTMLElement);
    });
    this.setCurrentPosition();
    this.loadHydrantMarkers();
    this.setupMapSearch();

    document.onfullscreenchange = (event) => {
      const target = event.target as HTMLInputElement;
      const pacContainerElements = document.getElementsByClassName('pac-container');
      if (pacContainerElements.length > 0) {
        const pacContainer = document.getElementsByClassName('pac-container')[0];
        if (pacContainer.parentElement === target) {
          document.getElementsByTagName('body')[0].appendChild(pacContainer);
          pacContainer.className += pacContainer.className.replace('fullscreen-pac-container', '');
        } else {
          target.appendChild(pacContainer);
          pacContainer.className += ' fullscreen-pac-container';
        }
      }
    };
  }

  setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.map?.setCenter(pos);
          this.map?.setZoom(this.currentLocation);
          if (this.currentPositionMarker === undefined) {
            const icon = document.createElement('img');
            icon.src = require(`../../../../assets/img/${this.currentLocationIcon}`);
            this.currentPositionMarker = new this.mapApi!.marker.AdvancedMarkerElement({
              position: pos,
              map: this.map,
              title: this.currentLocationTitle,
              content: icon,
            });
          } else {
            this.currentPositionMarker.position = pos;
          }
        },
      );
    }
  }

  async loadHydrantMarkers() {
    const infowindow = new this.mapApi!.maps.InfoWindow();
    const requestConfig = new RequestConfig();
    requestConfig.headers = {
      'X-API-Key': (Vue.$config.values as any)['hydrant-list-api-key'],
    };
    if (!this.fireDepartmentId) {
      this.request.fireDepartmentId = 1;
    } else {
      this.request.fireDepartmentId = this.fireDepartmentId;
    }
    try {
      this.hydrantMapItems = (await Vue.$service.v2.api.hydrants.getHydrantMapItemsList(this.request, requestConfig)).items;
      for (const item of this.hydrantMapItems) {
        const icon = document.createElement('img');
        if (item.data.icon) {
          icon.src = require(`../../../../assets/img/${item.data.icon.iconType?.toLowerCase()}_${item.data.icon.iconColor?.toLowerCase()}.svg`);
        }
        const hydrantMarker = new this.mapApi!.marker.AdvancedMarkerElement({
          position: new this.mapApi!.core.LatLng(item.data.locationLat as number | google.maps.LatLng | google.maps.LatLngLiteral, item.data.locationLng as number),
          map: this.map,
          content: icon,
        });

        let remarks = Vue.$t('page.app.hydrants.properties.noRemark');
        if (item.data.attributes?.remark) {
          remarks = item.data.attributes.remark;
        }
        if (item.data.type === 'Hydrant') {
          const content = `
          ${Vue.$t('page.app.hydrants.properties.nr.short')}.: ${item.data.attributes?.number}
          <br />${Vue.$t('page.app.hydrants.properties.adapter75')}: ${item.data.attributes?.adapter75Count}
          <br />${Vue.$t('page.app.hydrants.properties.adapter55')}: ${item.data.attributes?.adapter55Count}
          <br />${Vue.$t('page.app.hydrants.properties.address')}: ${item.data.attributes?.address}
          <br />${Vue.$t('page.app.hydrants.properties.remark')}: ${remarks}
          `;
          hydrantMarker.addListener('click', () => {
            infowindow.open({
              anchor: hydrantMarker,
              map: this.map,
            });
            infowindow.setContent(content);
          });
        }

        this.markers.push(hydrantMarker);
      }
    } catch (error: any) {
      Vue.$log.error(error);
    }
  }

  setupMapSearch() {
    this.searchBox = new this.mapApi!.places.SearchBox(this.searchFieldContainerElement);
    this.mapApi!.core.event.addListener(this.searchBox, 'places_changed', () => {
      const searchResult = this.searchBox.getPlaces();
      if (searchResult === undefined || searchResult.length === 0) {
        return;
      }
      const searchResultLat = searchResult[0].geometry?.location?.lat();
      const searchResultLng = searchResult[0].geometry?.location?.lng();
      if (searchResultLat && searchResultLng) {
        const panPoint = new this.mapApi!.core.LatLng(searchResultLat, searchResultLng);
        this.map?.panTo(panPoint);

        if (this.searchMarker === undefined) {
          this.searchMarker = new this.mapApi!.marker.AdvancedMarkerElement({
            position: panPoint,
            map: this.map,
          });
        } else {
          this.searchMarker.position = panPoint;
        }
      }
      this.showAddressSearchBox = false;
    });
  }

  async setHomePosition() {
    if (this.hydrantMapItems) {
      const fireDeps = this.hydrantMapItems.filter((x) => x.data.type === 'FireDepartment');
      if (fireDeps && fireDeps[0]) {
        const latLng = {
          lat: fireDeps[0].data.locationLat!,
          lng: fireDeps[0].data.locationLng!,
        };
        this.map?.setCenter(latLng);
        this.map?.setZoom(this.home);
      } else {
        Vue.$log.error(`Lat or Lang of FireDepartment ${this.fireDepartmentId} not set`);
      }
    }
  }

  setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].map = map;
    }
  }

  changeMakrerVisibility(event: any) {
    if (event.target.checked) {
      this.setMapOnAll(this.map!);
    } else {
      this.setMapOnAll(null);
    }
  }

  searchForAddress() {
    this.searchFieldContainerElement.value = '';
    this.showAddressSearchBox = true;
    this.mapApi!.core.event.addListenerOnce(this.map!, 'idle', () => {
      this.mapContainerElement.firstChild?.appendChild(this.searchBarRef as HTMLElement);
    });
    // TODO: set focus when openening search bar #9708
    // this.searchFieldContainerElement.focus();
  }
}
