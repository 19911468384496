import Vue, { VueConstructor } from 'vue';
import generatedApi from './_generated/generated-api';

const loadApi = () => ({
  ...generatedApi(),

  // Add custom apis here
  // persons: { ...personsServices(Vue.$service) },
});

export default {
  install: (VuePlugin: VueConstructor, options: any) => {
    VuePlugin.$guards.waitForAppLoad().then(() => {
      const coreApi = VuePlugin.$service.v2.api;
      VuePlugin.$service.v2.api = { ...coreApi, ...loadApi() };
      VuePlugin.prototype.$service.v2.api = Vue.$service.v2.api;
    });
  },
};

export type AppServiceAPIV2 = ReturnType<typeof loadApi>;

declare module '@glittr/frontend-core/src/plugins/servicelayer/servicelayer' {
  interface ServiceAPIV2 extends AppServiceAPIV2 { }
}
