// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCampaignFieldMappingsRequestModel from '../../model/get-campaign-field-mappings-request-model';
import GetCampaignFieldMappingByIdRequestModel from '../../model/get-campaign-field-mapping-by-id-request-model';
import UpdateCampaignFieldMappingRequestModel from '../../model/update-campaign-field-mapping-request-model';
import DeleteCampaignFieldMappingRequestModel from '../../model/delete-campaign-field-mapping-request-model';
import CreateCampaignFieldMappingRequestModel from '../../model/create-campaign-field-mapping-request-model';
import CampaignMappingFieldPagingResultModel from '../../model/campaign-mapping-field-paging-result-model';
import CampaignMappingFieldModel from '../../model/campaign-mapping-field-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCampaignFieldMappings(request: GetCampaignFieldMappingsRequestModel, config?: RequestConfig) {
    const requestDTO = GetCampaignFieldMappingsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-field-mappings'] ?? 'core/integration/FieldMapping/CampaignFieldMappings';
    const response = await service.get<any>(endpointPath, {
      query: {
        appSystemId: requestDTO.appSystemId,
        fieldId: requestDTO.fieldId,
        fieldValue: requestDTO.fieldValue,
        otherAppSystemId: requestDTO.otherAppSystemId,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CampaignMappingFieldPagingResultModel.toModel(dto));
  },
  /**  */
  async getCampaignFieldMappingById(request: GetCampaignFieldMappingByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCampaignFieldMappingByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-campaign-field-mapping-by-id'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CampaignMappingFieldModel.toModel(dto));
  },
  /**  */
  async updateCampaignFieldMapping(request: UpdateCampaignFieldMappingRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateCampaignFieldMappingRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-campaign-field-mapping'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        appSystem1Id: requestDTO.appSystem1Id,
        field1Id: requestDTO.field1Id,
        field1Value: requestDTO.field1Value,
        appSystem2Id: requestDTO.appSystem2Id,
        field2Id: requestDTO.field2Id,
        field2Value: requestDTO.field2Value,
      },
      ...config,
    });
  },
  /**  */
  async deleteCampaignFieldMapping(request: DeleteCampaignFieldMappingRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteCampaignFieldMappingRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-campaign-field-mapping'] ?? 'core/integration/FieldMapping/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async createCampaignFieldMapping(request: CreateCampaignFieldMappingRequestModel, config?: RequestConfig) {
    const requestDTO = CreateCampaignFieldMappingRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-campaign-field-mapping'] ?? 'core/integration/FieldMapping';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        appSystem1Id: requestDTO.appSystem1Id,
        field1Id: requestDTO.field1Id,
        field1Value: requestDTO.field1Value,
        appSystem2Id: requestDTO.appSystem2Id,
        field2Id: requestDTO.field2Id,
        field2Value: requestDTO.field2Value,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
