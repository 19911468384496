// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetCampaignFieldMappingsRequestModel from '../../model/get-campaign-field-mappings-request-model';
import GetCampaignFieldMappingsRequestDTO from '../dto/get-campaign-field-mappings-request-dto';
import dtoSchema from '../schemas/get-campaign-field-mappings-request-schema';

export default abstract class GetCampaignFieldMappingsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetCampaignFieldMappingsRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.appSystemId = data.appSystemId;
      this.fieldId = data.fieldId;
      this.fieldValue = data.fieldValue;
      this.otherAppSystemId = data.otherAppSystemId;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {int32}
  */
  appSystemId?: number;
  /**
  */
  fieldId?: string;
  /**
  */
  fieldValue?: string;
  /**
  * @type {int32}
  */
  otherAppSystemId?: number;

  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO>): GetCampaignFieldMappingsRequestModel;
  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO> | undefined | null): GetCampaignFieldMappingsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetCampaignFieldMappingsRequestDTO> | undefined | null): GetCampaignFieldMappingsRequestModel | undefined {
    return dto ? new GetCampaignFieldMappingsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetCampaignFieldMappingsRequestModel> | GetCampaignFieldMappingsRequestModel): GetCampaignFieldMappingsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      appSystemId: unwrapped.appSystemId,
      fieldId: unwrapped.fieldId,
      fieldValue: unwrapped.fieldValue,
      otherAppSystemId: unwrapped.otherAppSystemId,
    } as GetCampaignFieldMappingsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetCampaignFieldMappingsRequestModel, can not map to GetCampaignFieldMappingsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
