// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import IntegrationCallListPagingResultModel from '../../model/integration-call-list-paging-result-model';
import IntegrationCallListPagingResultDTO from '../dto/integration-call-list-paging-result-dto';
import dtoSchema from '../schemas/integration-call-list-paging-result-schema';
import IntegrationCallListModel from '../../model/integration-call-list-model';
import ResourceLinkModel from '../../model/resource-link-model';
import PaginationDataModel from '../../model/pagination-data-model';

export default abstract class IntegrationCallListPagingResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<IntegrationCallListPagingResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => IntegrationCallListModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.paginationData = data.paginationData ? PaginationDataModel.toModel(data.paginationData) : undefined;
    }
  }
  /**
  */
  items?: IntegrationCallListModel[];
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  paginationData?: PaginationDataModel;

  static toModel(dto: DeepPartial<IntegrationCallListPagingResultDTO>): IntegrationCallListPagingResultModel;
  static toModel(dto: DeepPartial<IntegrationCallListPagingResultDTO> | undefined | null): IntegrationCallListPagingResultModel | undefined;
  static toModel(dto: DeepPartial<IntegrationCallListPagingResultDTO> | undefined | null): IntegrationCallListPagingResultModel | undefined {
    return dto ? new IntegrationCallListPagingResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<IntegrationCallListPagingResultModel> | IntegrationCallListPagingResultModel): IntegrationCallListPagingResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
      paginationData: unwrapped.paginationData,
    } as IntegrationCallListPagingResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for IntegrationCallListPagingResultModel, can not map to IntegrationCallListPagingResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
