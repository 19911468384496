// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/integration-task-schema',
  type: 'object',
  required: ['id', 'creatorId', 'integrationTaskTypeId'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    name: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    integrationTaskTypeId: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
