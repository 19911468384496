// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/campaign-mapping-field-schema',
  type: 'object',
  required: ['id', 'creatorId', 'appSystem1Id', 'field1Id', 'appSystem2Id', 'field2Id'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    appSystem1Id: {
      type: 'integer',
    },
    field1Id: {
      type: 'string',
      minLength: 1,
    },
    field1Value: {
      type: 'string',
      nullable: true,
    },
    appSystem2Id: {
      type: 'integer',
    },
    field2Id: {
      type: 'string',
      minLength: 1,
    },
    field2Value: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
