// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-widget-java-script-url-request-schema',
  type: 'object',
  required: ['id'],
  properties: {
    successUrl: {
      type: 'string',
      nullable: true,
    },
    failedUrl: {
      type: 'string',
      nullable: true,
    },
    id: {
      type: 'string',
    },
  },
} as IJSONSchema;

export default schema;
