// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CampaignMappingFieldModel from '../../model/campaign-mapping-field-model';
import CampaignMappingFieldDTO from '../dto/campaign-mapping-field-dto';
import dtoSchema from '../schemas/campaign-mapping-field-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CampaignMappingFieldModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CampaignMappingFieldDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.appSystem1Id = data.appSystem1Id;
      this.field1Id = data.field1Id;
      this.field1Value = data.field1Value ?? undefined;
      this.appSystem2Id = data.appSystem2Id;
      this.field2Id = data.field2Id;
      this.field2Value = data.field2Value ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {int32}
  */
  appSystem1Id?: number;
  /**
  * @minimum 1
  */
  field1Id?: string;
  /**
  */
  field1Value?: string;
  /**
  * @type {int32}
  */
  appSystem2Id?: number;
  /**
  * @minimum 1
  */
  field2Id?: string;
  /**
  */
  field2Value?: string;

  static toModel(dto: DeepPartial<CampaignMappingFieldDTO>): CampaignMappingFieldModel;
  static toModel(dto: DeepPartial<CampaignMappingFieldDTO> | undefined | null): CampaignMappingFieldModel | undefined;
  static toModel(dto: DeepPartial<CampaignMappingFieldDTO> | undefined | null): CampaignMappingFieldModel | undefined {
    return dto ? new CampaignMappingFieldModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CampaignMappingFieldModel> | CampaignMappingFieldModel): CampaignMappingFieldDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      appSystem1Id: unwrapped.appSystem1Id,
      field1Id: unwrapped.field1Id,
      field1Value: unwrapped.field1Value,
      appSystem2Id: unwrapped.appSystem2Id,
      field2Id: unwrapped.field2Id,
      field2Value: unwrapped.field2Value,
    } as CampaignMappingFieldDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CampaignMappingFieldModel, can not map to CampaignMappingFieldDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
