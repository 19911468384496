import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import CreateHydrantRequestModel from '../../../../services/v2/model/create-hydrant-request-model';

export default class CreateHydrantViewModel extends ViewModelBase {
  initialize(): Promise<void> {
    throw new Error('Method not implemented.');
  }
  newHydrant = new CreateHydrantRequestModel();

  createHydrantDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.hydrants.getHydrantById,
    insertCommand: Vue.$service.v2.api.hydrants.createHydrant,
  });

  fireDepartmentResource = new DataSource({
    selectCommand: Vue.$service.v2.api.fireDepartments.getFireDepartmentList,
  });

  async addHydrant() {
    // when default value of p-location is set and user doesn't change it when adding a new hydrant, the newHydrant.lat / newHydrant.lng remains undefined
    // it is not expected for the user to get a validation error, when the values on the p-location look like they are set (with the default value)
    // TODO: improve solution in future version
    if (!this.newHydrant.lat) { this.newHydrant.lat = 47.1174765953623; }
    if (!this.newHydrant.lng) { this.newHydrant.lng = 9.149508327579966; }
    if (this.newHydrant.validate()) {
      const createdHydrantId = await this.createHydrantDataSource.insert(this.newHydrant);
      Vue.$router.push(`/Fw/Hydrants/admin/fireDepartment/${this.newHydrant.fireDepartmentId}/hydrants/${createdHydrantId.data}/edit`);
    }
  }
}
