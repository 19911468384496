<template>
  <main class="hydrants">
    <div v-show="page.showAddressSearchBox" ref="searchBarRef" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="modal-default-button" type="button" @click="page.showAddressSearchBox = false">
              <span class="material-icons">close</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="address-searchfield">
              <input id="address" ref="searchField" type="text" name="address" placeholder="Adresse suchen..." />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mapWrapper">
      <p-col xs12 sm8>
        <div id="map" ref="hydrantsMap" class="map">
        </div>
      </p-col>
      <section id="customMapButtons" ref="customMapButtons" class="sidebarContent">
        <button class="myPosition gm-control-active gm-fullscreen-control" type="button" @click="page.setCurrentPosition()">
          <span class="material-icons">gps_fixed</span>
        </button>
        <button class="home gm-control-active gm-fullscreen-control" type="button" @click="page.setHomePosition()">
          <span class="material-icons">home</span>
        </button>
        <button class="searchAddress gm-control-active gm-fullscreen-control" type="button" @click="page.searchForAddress()">
          <span class="material-icons">search</span>
        </button>
      </section>
    </div>
  </main>
</template>

<script lang="ts">
  import Vue from 'vue';
  import IndexPage from './index.vue.model';

  export default Vue.extend({
    auth: false,
    data: () => ({
      page: new IndexPage(),
    }),
    async mounted() {
      await this.page.initialize();
      this.page.mapContainerElement = this.$refs.hydrantsMap as HTMLElement;
      this.page.searchFieldContainerElement = this.$refs.searchField as HTMLInputElement;
      this.page.searchBarRef = this.$refs.searchBarRef as HTMLElement;

      await this.setFireDepartment();
      await this.page.populateGoogleMaps(this.$refs.customMapButtons as HTMLElement, this.page.searchBarRef);
    },
    methods: {
      async setFireDepartment() {
        const fireDepParam = Number.parseInt(this.$route.params.fireDepartmentId, 10);
        if (fireDepParam) {
          this.page.fireDepartmentId = fireDepParam;
        } else {
          const lastVisitedFireDepartment = this.$localStorage.get<Number>('LastVisitedFireDepartment');
          if (lastVisitedFireDepartment) {
            this.$router.replace(`/Fw/Hydrants/fireDepartment/${lastVisitedFireDepartment}`);
            return;
          }
          if (this.$auth.isLoggedIn) {
            try {
              const user = await this.$auth.getUser();
              this.page.fireDepartmentDataSource.filter.userId = user?.id!;
              const userFireDeps = await this.page.fireDepartmentDataSource.select();
              if (userFireDeps.items.length > 0) {
                this.$router.replace(`/Fw/Hydrants/fireDepartment/${userFireDeps.items[0].data.id}`);
                return;
              }
            } catch (e) {
              this.$log.error(`Unexpected error setting fireDepartment: ${e}`);
            }
          }
          this.$router.replace('/Fw/Hydrants/fireDepartment/1');
        }
      },
    },
  });
</script>
