// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OnlinePaymentTransactionProcessingModel from '../../model/online-payment-transaction-processing-model';
import OnlinePaymentTransactionProcessingDTO from '../dto/online-payment-transaction-processing-dto';
import dtoSchema from '../schemas/online-payment-transaction-processing-schema';

export default abstract class OnlinePaymentTransactionProcessingModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OnlinePaymentTransactionProcessingDTO>) {
    super();
    if (data) {
      this.transactionId = data.transactionId ?? undefined;
    }
  }
  /**
  */
  transactionId?: string;

  static toModel(dto: DeepPartial<OnlinePaymentTransactionProcessingDTO>): OnlinePaymentTransactionProcessingModel;
  static toModel(dto: DeepPartial<OnlinePaymentTransactionProcessingDTO> | undefined | null): OnlinePaymentTransactionProcessingModel | undefined;
  static toModel(dto: DeepPartial<OnlinePaymentTransactionProcessingDTO> | undefined | null): OnlinePaymentTransactionProcessingModel | undefined {
    return dto ? new OnlinePaymentTransactionProcessingModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OnlinePaymentTransactionProcessingModel> | OnlinePaymentTransactionProcessingModel): OnlinePaymentTransactionProcessingDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      transactionId: unwrapped.transactionId,
    } as OnlinePaymentTransactionProcessingDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OnlinePaymentTransactionProcessingModel, can not map to OnlinePaymentTransactionProcessingDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
