// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/create-hydrant-request-schema',
  type: 'object',
  required: ['fireDepartmentId', 'number', 'address', 'location', 'adapter75Count', 'adapter55Count', 'lat', 'lng'],
  properties: {
    fireDepartmentId: {
      type: 'integer',
    },
    number: {
      type: 'integer',
    },
    address: {
      type: 'string',
      minLength: 1,
    },
    location: {
      type: 'string',
      minLength: 1,
    },
    adapter75Count: {
      type: 'integer',
    },
    adapter55Count: {
      type: 'integer',
    },
    remark: {
      type: 'string',
      nullable: true,
    },
    lat: {
      type: 'number',
    },
    lng: {
      type: 'number',
    },
  },
} as IJSONSchema;

export default schema;
