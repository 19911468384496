<template>
  <p-container>
    <p-row no-gutters align-center>
      <p-col class="mr-1" shrink>
        <p-button tertiary @click="$router.push('/Fw/Hydrants/admin/hydrants')">
          <p-icon>arrow_back</p-icon>
        </p-button>
      </p-col>
      <p-col grow>
        <h2 class="mb-2">
          {{ $t('page.app.hydrantsAdmin.new.title') }}
        </h2>
      </p-col>
    </p-row>
    <p-divider class="mb-4"></p-divider>
    <p-card class="editView">
      <p-form :model="vm.newHydrant">
        <p-row class="editHydrantContainer">
          <p-col>
            <p-number-field v-model="vm.newHydrant.number" :label="$t('page.app.hydrants.properties.nr.long')" placeholder="0" required></p-number-field>
            <p-text-field v-model="vm.newHydrant.address" :label="$t('page.app.hydrants.properties.address')" required></p-text-field>
            <p-text-field v-model="vm.newHydrant.location" :label="$t('page.app.hydrants.properties.location')" required></p-text-field>
            <p-number-field v-model="vm.newHydrant.adapter55Count" :label="$t('page.app.hydrants.properties.adapter55')" placeholder="0" required></p-number-field>
            <p-number-field v-model="vm.newHydrant.adapter75Count" :label="$t('page.app.hydrants.properties.adapter75')" placeholder="0" required></p-number-field>
            <p-textarea v-model="vm.newHydrant.remark" :label="$t('page.app.hydrants.properties.remark')"></p-textarea>
          </p-col>
          <p-divider vertical class="mr-2 ml-2"></p-divider>
          <p-col>
            <!-- TODO: use this when bug #9323 in core is fixes and props are reactive -->
            <!-- <p-location
              :default-latitude="fireDepartmentCustomModel.fireDepLat"
              :default-longitude="fireDepartmentCustomModel.fireDepLng"
              @latitudeChange="vm.newHydrant.lat = $event"
              @longitudeChange="vm.newHydrant.lng = $event"
            ></p-location> -->
            <p-location
              :default-latitude="47.1174765953623"
              :default-longitude="9.149508327579966"
              @latitudeChange="vm.newHydrant.lat = $event"
              @longitudeChange="vm.newHydrant.lng = $event"
            ></p-location>
          </p-col>
        </p-row>
        <p-row class="mt-4" justify-end>
          <p-button secondary @click="$router.back()">
            {{ $t('page.app.hydrantsAdmin.cancel') }}
          </p-button>
          <p-button class="mr-4" @click="vm.addHydrant()">
            {{ $t('page.app.hydrantsAdmin.save') }}
          </p-button>
        </p-row>
      </p-form>
    </p-card>
    <p-request-alert v-model="vm.createHydrantDataSource.error" class="mt-2"></p-request-alert>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CreateHydrantViewModel from './new.vue.model';
  import FireDepartmentCustomModel from '../../../customModels/fireDepartment';

  export default Vue.extend({
    auth: true,
    data: () => ({
      vm: new CreateHydrantViewModel(),
      fireDepartmentCustomModel: new FireDepartmentCustomModel(),
    }),
    mounted() {
      this.vm.newHydrant.fireDepartmentId = Number.parseInt(this.$route.params.fireDepartmentId, 10);
      // TODO: selectedFireDepartment as param --> only works when bug #9323 is fixed & mounted needs to be async
      // await this.fireDepartmentCustomModel.setFireDepartmentLatLngById(this.vm.fireDepId);
    },
  });
</script>
