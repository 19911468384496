// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';
import Int64LookupListResultDTO from '../dto/int64-lookup-list-result-dto';
import dtoSchema from '../schemas/int64-lookup-list-result-schema';
import Int64LookupModel from '../../model/int64-lookup-model';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class Int64LookupListResultModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Int64LookupListResultDTO>) {
    super();
    if (data) {
      this.items = data.items?.map((i) => Int64LookupModel.toModel(i)) ?? [];
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  items?: Int64LookupModel[];
  /**
  */
  links?: ResourceLinkModel[];

  static toModel(dto: DeepPartial<Int64LookupListResultDTO>): Int64LookupListResultModel;
  static toModel(dto: DeepPartial<Int64LookupListResultDTO> | undefined | null): Int64LookupListResultModel | undefined;
  static toModel(dto: DeepPartial<Int64LookupListResultDTO> | undefined | null): Int64LookupListResultModel | undefined {
    return dto ? new Int64LookupListResultModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Int64LookupListResultModel> | Int64LookupListResultModel): Int64LookupListResultDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      items: unwrapped.items,
      links: unwrapped.links,
    } as Int64LookupListResultDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Int64LookupListResultModel, can not map to Int64LookupListResultDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
