// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/integration-call-state-overview-schema',
  type: 'object',
  required: ['stateId', 'count'],
  nullable: true,
  properties: {
    integrationDay: {
      type: 'string',
      format: 'date-time',
    },
    stateId: {
      type: 'integer',
    },
    stateName: {
      type: 'string',
      nullable: true,
    },
    count: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
