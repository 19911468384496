import Vue from 'vue';
import GetFireDepartmentByIdRequestModel from '../../services/v2/model/get-fire-department-by-id-request-model';

// custom model, because this functionality will be used multiple times in different places
class FireDepartmentCustomModel {
  fireDepLat = 0;
  fireDepLng = 0;
  async setFireDepartmentLatLngById(fireDepId: number) {
    if (fireDepId === undefined) {
      // set default lat & lng to Obstalden if no fireDep provided
      this.fireDepLat = 47.1174765953623;
      this.fireDepLng = 9.149508327579966;
    }
    const req = new GetFireDepartmentByIdRequestModel();
    req.id = fireDepId;
    const fireDepartment = await Vue.$service.v2.api.fireDepartments.getFireDepartmentById(req);
    this.fireDepLat = fireDepartment.data.lat!;
    this.fireDepLng = fireDepartment.data.lng!;
  }
}

export default FireDepartmentCustomModel;
