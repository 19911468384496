// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetLocationsRequestModel from '../../model/get-locations-request-model';
import GetLocationByIdRequestModel from '../../model/get-location-by-id-request-model';
import LookupLocationsRequestModel from '../../model/lookup-locations-request-model';
import LookupCountriesRequestModel from '../../model/lookup-countries-request-model';
import LookupStatesRequestModel from '../../model/lookup-states-request-model';
import LocationCitySearchPagingResultModel from '../../model/location-city-search-paging-result-model';
import LocationCitySearchModel from '../../model/location-city-search-model';
import Int64LookupPagingResultModel from '../../model/int64-lookup-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getLocations(request: GetLocationsRequestModel, config?: RequestConfig) {
    const requestDTO = GetLocationsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-locations'] ?? 'core/Locations';
    const response = await service.get<any>(endpointPath, {
      query: {
        postalCodeCriteria: requestDTO.postalCodeCriteria,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LocationCitySearchPagingResultModel.toModel(dto));
  },
  /**  */
  async getLocationById(request: GetLocationByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetLocationByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-location-by-id'] ?? 'core/Locations/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(LocationCitySearchModel.toModel(dto));
  },
  /**  */
  async lookupLocations(request: LookupLocationsRequestModel, config?: RequestConfig) {
    const requestDTO = LookupLocationsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-locations'] ?? 'core/Locations/LookupLocations';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async lookupCountries(request: LookupCountriesRequestModel, config?: RequestConfig) {
    const requestDTO = LookupCountriesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-countries'] ?? 'core/Locations/LookupCountries';
    const response = await service.get<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
  /**  */
  async lookupStates(request: LookupStatesRequestModel, config?: RequestConfig) {
    const requestDTO = LookupStatesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-states'] ?? 'core/Locations/LookupStates';
    const response = await service.get<any>(endpointPath, {
      query: {
        countryId: requestDTO.countryId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupPagingResultModel.toModel(dto));
  },
});
