// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetTokenRequestModel from '../../model/get-token-request-model';
import RefreshTokenRequestModel from '../../model/refresh-token-request-model';
import AuthTokenModel from '../../model/auth-token-model';

export default (service: Servicelayer) => ({
  /**  */
  async getToken(request: GetTokenRequestModel, config?: RequestConfig) {
    const requestDTO = GetTokenRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-token'] ?? 'core/Auth/GetToken';
    const response = await service.post<any>(endpointPath, {
      body: {
        userName: requestDTO.userName,
        password: requestDTO.password,
        shareId: requestDTO.shareId,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AuthTokenModel.toModel(dto));
  },
  /**  */
  async refreshToken(request: RefreshTokenRequestModel, config?: RequestConfig) {
    const requestDTO = RefreshTokenRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-refresh-token'] ?? 'core/Auth/RefreshToken';
    const response = await service.post<any>(endpointPath, {
      body: {
        accessToken: requestDTO.accessToken,
        refreshToken: requestDTO.refreshToken,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AuthTokenModel.toModel(dto));
  },
});
