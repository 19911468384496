// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAppSystemWebApiByIdRequestModel from '../../model/get-app-system-web-api-by-id-request-model';
import UpdateAppSystemWebApiRequestModel from '../../model/update-app-system-web-api-request-model';
import DeleteAppSystemWebApiRequestModel from '../../model/delete-app-system-web-api-request-model';
import GetAppSystemWebApiListRequestModel from '../../model/get-app-system-web-api-list-request-model';
import CreateAppSystemWebApiRequestModel from '../../model/create-app-system-web-api-request-model';
import CheckWebApiConnectionRequestModel from '../../model/check-web-api-connection-request-model';
import AppSystemWebApiModel from '../../model/app-system-web-api-model';
import AppSystemWebApiPagingResultModel from '../../model/app-system-web-api-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getAppSystemWebApiById(request: GetAppSystemWebApiByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemWebApiByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-web-api-by-id'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemWebApiModel.toModel(dto));
  },
  /**  */
  async updateAppSystemWebApi(request: UpdateAppSystemWebApiRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateAppSystemWebApiRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-app-system-web-api'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        appSystemId: requestDTO.appSystemId,
        apiUrl: requestDTO.apiUrl,
        apiUser: requestDTO.apiUser,
        apiPassword: requestDTO.apiPassword,
        hasEncryptedApiPassword: requestDTO.hasEncryptedApiPassword,
        apiAuthenticationType: requestDTO.apiAuthenticationType,
        timeoutSeconds: requestDTO.timeoutSeconds,
        useProxy: requestDTO.useProxy,
        proxyUrl: requestDTO.proxyUrl,
        proxyUser: requestDTO.proxyUser,
        proxyPassword: requestDTO.proxyPassword,
        hasEncryptedProxyPassword: requestDTO.hasEncryptedProxyPassword,
        enableHttpsCertificateValidation: requestDTO.enableHttpsCertificateValidation,
        workspaceId: requestDTO.workspaceId,
        secret: requestDTO.secret,
        hasEncryptedSecret: requestDTO.hasEncryptedSecret,
      },
      ...config,
    });
  },
  /**  */
  async deleteAppSystemWebApi(request: DeleteAppSystemWebApiRequestModel, config?: RequestConfig) {
    const requestDTO = DeleteAppSystemWebApiRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-delete-app-system-web-api'] ?? 'core/integration/AppSystemWebApis/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.delete<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getAppSystemWebApiList(request: GetAppSystemWebApiListRequestModel, config?: RequestConfig) {
    const requestDTO = GetAppSystemWebApiListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-app-system-web-api-list'] ?? 'core/integration/AppSystemWebApis';
    const response = await service.get<any>(endpointPath, {
      query: {
        appSystemId: requestDTO.appSystemId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AppSystemWebApiPagingResultModel.toModel(dto));
  },
  /**  */
  async createAppSystemWebApi(request: CreateAppSystemWebApiRequestModel, config?: RequestConfig) {
    const requestDTO = CreateAppSystemWebApiRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-app-system-web-api'] ?? 'core/integration/AppSystemWebApis';
    const response = await service.post<any>(endpointPath, {
      body: {
        appSystemId: requestDTO.appSystemId,
        apiUrl: requestDTO.apiUrl,
        apiUser: requestDTO.apiUser,
        apiPassword: requestDTO.apiPassword,
        apiAuthenticationType: requestDTO.apiAuthenticationType,
        timeoutSeconds: requestDTO.timeoutSeconds,
        useProxy: requestDTO.useProxy,
        proxyUrl: requestDTO.proxyUrl,
        proxyUser: requestDTO.proxyUser,
        proxyPassword: requestDTO.proxyPassword,
        enableHttpsCertificateValidation: requestDTO.enableHttpsCertificateValidation,
        workspaceId: requestDTO.workspaceId,
        secret: requestDTO.secret,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async checkWebApiConnection(request: CheckWebApiConnectionRequestModel, config?: RequestConfig) {
    const requestDTO = CheckWebApiConnectionRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-check-web-api-connection'] ?? 'core/integration/AppSystemWebApis/CheckWebApiConnection';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        appSystemId: requestDTO.appSystemId,
        apiUrl: requestDTO.apiUrl,
        apiUser: requestDTO.apiUser,
        apiPassword: requestDTO.apiPassword,
        hasEncryptedApiPassword: requestDTO.hasEncryptedApiPassword,
        apiAuthenticationType: requestDTO.apiAuthenticationType,
        timeoutSeconds: requestDTO.timeoutSeconds,
        useProxy: requestDTO.useProxy,
        proxyUrl: requestDTO.proxyUrl,
        proxyUser: requestDTO.proxyUser,
        proxyPassword: requestDTO.proxyPassword,
        hasEncryptedProxyPassword: requestDTO.hasEncryptedProxyPassword,
        enableHttpsCertificateValidation: requestDTO.enableHttpsCertificateValidation,
        workspaceId: requestDTO.workspaceId,
        secret: requestDTO.secret,
        hasEncryptedSecret: requestDTO.hasEncryptedSecret,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
});
