// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateSequenceNumberRequestModel from '../../model/create-sequence-number-request-model';
import CreateSequenceNumberRequestDTO from '../dto/create-sequence-number-request-dto';
import dtoSchema from '../schemas/create-sequence-number-request-schema';

export default abstract class CreateSequenceNumberRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateSequenceNumberRequestDTO>) {
    super();
    if (data) {
      this.name = data.name;
      this.startNumber = data.startNumber;
      this.increment = data.increment;
    }
  }
  /**
  * @minimum 1
  */
  name?: string;
  /**
  * @type {int32}
  */
  startNumber?: number;
  /**
  * @type {int32}
  */
  increment?: number;

  static toModel(dto: DeepPartial<CreateSequenceNumberRequestDTO>): CreateSequenceNumberRequestModel;
  static toModel(dto: DeepPartial<CreateSequenceNumberRequestDTO> | undefined | null): CreateSequenceNumberRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateSequenceNumberRequestDTO> | undefined | null): CreateSequenceNumberRequestModel | undefined {
    return dto ? new CreateSequenceNumberRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateSequenceNumberRequestModel> | CreateSequenceNumberRequestModel): CreateSequenceNumberRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      name: unwrapped.name,
      startNumber: unwrapped.startNumber,
      increment: unwrapped.increment,
    } as CreateSequenceNumberRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateSequenceNumberRequestModel, can not map to CreateSequenceNumberRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
