// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PatchContactIdMappingRequestModel from '../../model/patch-contact-id-mapping-request-model';
import PatchContactIdMappingRequestDTO from '../dto/patch-contact-id-mapping-request-dto';
import dtoSchema from '../schemas/patch-contact-id-mapping-request-schema';

export default abstract class PatchContactIdMappingRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PatchContactIdMappingRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.crmId = data.crmId;
      this.crmStateId = data.crmStateId;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  * @type {uuid}
  */
  crmId?: string;
  /**
  * @type {int32}
  */
  crmStateId?: number;

  static toModel(dto: DeepPartial<PatchContactIdMappingRequestDTO>): PatchContactIdMappingRequestModel;
  static toModel(dto: DeepPartial<PatchContactIdMappingRequestDTO> | undefined | null): PatchContactIdMappingRequestModel | undefined;
  static toModel(dto: DeepPartial<PatchContactIdMappingRequestDTO> | undefined | null): PatchContactIdMappingRequestModel | undefined {
    return dto ? new PatchContactIdMappingRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PatchContactIdMappingRequestModel> | PatchContactIdMappingRequestModel): PatchContactIdMappingRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      crmId: unwrapped.crmId,
      crmStateId: unwrapped.crmStateId,
    } as PatchContactIdMappingRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PatchContactIdMappingRequestModel, can not map to PatchContactIdMappingRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
