// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/check-web-api-connection-request-schema',
  type: 'object',
  required: ['id', 'creatorId', 'appSystemId', 'timeoutSeconds'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    appSystemId: {
      type: 'integer',
    },
    apiUrl: {
      type: 'string',
      nullable: true,
    },
    apiUser: {
      type: 'string',
      nullable: true,
    },
    apiPassword: {
      type: 'string',
      nullable: true,
    },
    hasEncryptedApiPassword: {
      type: 'boolean',
    },
    apiAuthenticationType: {
      type: 'string',
      nullable: true,
    },
    timeoutSeconds: {
      type: 'integer',
    },
    useProxy: {
      type: 'boolean',
    },
    proxyUrl: {
      type: 'string',
      nullable: true,
    },
    proxyUser: {
      type: 'string',
      nullable: true,
    },
    proxyPassword: {
      type: 'string',
      nullable: true,
    },
    hasEncryptedProxyPassword: {
      type: 'boolean',
    },
    enableHttpsCertificateValidation: {
      type: 'boolean',
    },
    workspaceId: {
      type: 'string',
      nullable: true,
    },
    secret: {
      type: 'string',
      nullable: true,
    },
    hasEncryptedSecret: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
