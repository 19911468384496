// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetCallLogsRequestModel from '../../model/get-call-logs-request-model';
import GetCallLogsRequestDTO from '../dto/get-call-logs-request-dto';
import dtoSchema from '../schemas/get-call-logs-request-schema';

export default abstract class GetCallLogsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetCallLogsRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.correlationId = data.correlationId;
      this.from = data.from;
      this.to = data.to;
      this.requestPath = data.requestPath;
      this.requestTypes = data.requestTypes;
      this.targetSystems = data.targetSystems;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  */
  correlationId?: string;
  /**
  * @type {date-time}
  */
  from?: string;
  /**
  * @type {date-time}
  */
  to?: string;
  /**
  */
  requestPath?: string;
  /**
  */
  requestTypes?: string[];
  /**
  */
  targetSystems?: string[];
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetCallLogsRequestDTO>): GetCallLogsRequestModel;
  static toModel(dto: DeepPartial<GetCallLogsRequestDTO> | undefined | null): GetCallLogsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetCallLogsRequestDTO> | undefined | null): GetCallLogsRequestModel | undefined {
    return dto ? new GetCallLogsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetCallLogsRequestModel> | GetCallLogsRequestModel): GetCallLogsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      correlationId: unwrapped.correlationId,
      from: unwrapped.from,
      to: unwrapped.to,
      requestPath: unwrapped.requestPath,
      requestTypes: unwrapped.requestTypes,
      targetSystems: unwrapped.targetSystems,
      searchCriteria: unwrapped.searchCriteria,
    } as GetCallLogsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetCallLogsRequestModel, can not map to GetCallLogsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
