import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import HydrantModelBase from '../_generated/modelBases/hydrant-model-base';

export default class HydrantModel extends HydrantModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs
  schema: IJSONSchema = {
    ...queryBuilder.properties({
      number: { minimum: 0 },
      address: { maxLength: 255 },
      location: { maxLength: 255 },
      adapter55Count: { minimum: 0 },
      adapter75Count: { minimum: 0 },
      remark: { maxLength: 255 },
    }),
  };
}
