import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import HydrantModel from '../../../../services/v2/model/hydrant-model';

export default class HydrantsListViewModel extends ViewModelBase {
  async initialize(): Promise<void> {
    this.fireDepartmentDataSource.filter.userId = Vue.$auth.user?.id!;
    await this.fireDepartmentDataSource.select();
    await this.lookupFireDepartmentsDataSource.select();
    this.hydrantsDataSource.filter.fireDepartmentId = this.hydrantsDataSource.filter.fireDepartmentId ?? this.lookupFireDepartmentsDataSource.data?.items[0]?.data.id;
  }

  get lookupFireDepartmentItems() {
    return this.lookupFireDepartmentsDataSource.data?.items.map((record) => record.data) ?? [];
  }

  hydrantsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.hydrants.getPagedHydrantList,
    deleteCommand: Vue.$service.v2.api.hydrants.deleteHydrant,
  });

  fireDepartmentDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.fireDepartments.getFireDepartmentList,
  });

  lookupFireDepartmentsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.fireDepartments.getLookupFireDepartment,
  });

  async onDeleteClick(entry: DataRecord<HydrantModel>) {
    Vue.$dialog.confirmDelete(1)
      .then(async (isConfirmed) => {
        if (isConfirmed) {
          await this.hydrantsDataSource.delete(entry.data.id!);
          this.hydrantsDataSource.select();
        }
      });
  }
}
