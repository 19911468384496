// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ReExecuteIntegrationCallRequestModel from '../../model/re-execute-integration-call-request-model';
import ReExecuteIntegrationCallRequestDTO from '../dto/re-execute-integration-call-request-dto';
import dtoSchema from '../schemas/re-execute-integration-call-request-schema';

export default abstract class ReExecuteIntegrationCallRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ReExecuteIntegrationCallRequestDTO>) {
    super();
    if (data) {
      this.comment = data.comment;
      this.payload = data.payload;
      this.id = data.id;
    }
  }
  /**
  * @minimum 1
  */
  comment?: string;
  /**
  * @minimum 1
  */
  payload?: string;
  /**
  * @type {int64}
  */
  id?: number;

  static toModel(dto: DeepPartial<ReExecuteIntegrationCallRequestDTO>): ReExecuteIntegrationCallRequestModel;
  static toModel(dto: DeepPartial<ReExecuteIntegrationCallRequestDTO> | undefined | null): ReExecuteIntegrationCallRequestModel | undefined;
  static toModel(dto: DeepPartial<ReExecuteIntegrationCallRequestDTO> | undefined | null): ReExecuteIntegrationCallRequestModel | undefined {
    return dto ? new ReExecuteIntegrationCallRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ReExecuteIntegrationCallRequestModel> | ReExecuteIntegrationCallRequestModel): ReExecuteIntegrationCallRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      comment: unwrapped.comment,
      payload: unwrapped.payload,
      id: unwrapped.id,
    } as ReExecuteIntegrationCallRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ReExecuteIntegrationCallRequestModel, can not map to ReExecuteIntegrationCallRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
