// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import Int64LookupSchema from './int64-lookup-schema';
import HydrantMapItemIconSchema from './hydrant-map-item-icon-schema';

const schema = {
  $id: '/hydrant-map-item-schema',
  type: 'object',
  required: ['locationLat', 'locationLng'],
  nullable: true,
  properties: {
    lookupData: {
      ...Int64LookupSchema,
    },
    type: {
      type: 'string',
      nullable: true,
    },
    icon: {
      ...HydrantMapItemIconSchema,
    },
    locationLat: {
      type: 'number',
    },
    locationLng: {
      type: 'number',
    },
    attributes: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
