import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import { DataRecordWrapResponse } from '@glittr/frontend-core/src/core/v2/data/data-record';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class EditHydrantViewModel extends ViewModelBase {
  successMessage = undefined as any;

  async initialize(): Promise<void> {
    await this.editHydrantDataSource.select();
  }

  editHydrantDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.hydrants.getHydrantById,
    updateCommand: Vue.$service.v2.api.hydrants.updateHydrant,
  });

  async getHydrant() {
    await this.editHydrantDataSource.select();
  }

  async updateHydrant() {
    if (this.editHydrantDataSource.data) {
      const updateHydrantData = this.editHydrantDataSource.data.data;
      if (updateHydrantData.validate()) {
        await this.editHydrantDataSource.update(updateHydrantData);
        this.successMessage = 'Hydrant Erfolgreich aktualisiert';
        this.editHydrantDataSource.select();
      }
    }
  }
}
